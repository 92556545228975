import React from 'react';
import HomeLogo from '../../components/Home/HomeLogo';
import { Link } from 'react-router-dom';
import '../../style/App.css';

function HomePage() {
  return (
    <div className="App">
    <header className="App-header">
      <HomeLogo />
      <p>
        AI-Powered Trading Analysis Platform
      </p>
      <Link to="/dashboard">Dashboard</Link>
    </header>
    <div></div>
    <div></div>
    <div></div>
    </div>
  );
}

export default HomePage;