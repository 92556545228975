import React from 'react';
import '../../style/App.css';// Make sure to import your CSS file
import logo from '../../style/Content/logo.svg';

const HomeLogo = () => {
  return (
    <div className="logo-container">
      <img src={logo} className="logo" alt="logo" />
    </div>
  );
};

export default HomeLogo;
